<template>
  <div>
    <b-card>
      <h2>{{ aboutContent.title }}</h2>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="aboutContent.detailText" />
      <h3>Правила клуба</h3>
      <b-list-group flush>
        <b-list-group-item
          v-for="(elpElement, index) in helpElements"
          :key="elpElement.id"
          class="d-flex"
          :to="{ name: 'help-view', params: { id: elpElement.id } }"
        >
          <b-avatar
            text="PI"
            :variant="`light-${generateIndexVariant(index)}`"
            size="22"
            class="mr-1"
          >
            <span class="d-flex align-items-center">
              <feather-icon
                icon="ArrowRightCircleIcon"
                size="16"
              />
            </span>
          </b-avatar>
          <span class="h5">{{ elpElement.title }}</span>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BCard, BAvatar, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import contentGeneralService from '@/application/contentGeneralService'
import contentHelpService from '@/application/contentHelpService'
import { generateIndexVariant } from '@/utils/UserFilter'

export default {
  components: {
    BCard,
    BAvatar,
    BListGroup,
    BListGroupItem,
  },
  setup() {
    const aboutContent = ref({})
    const helpElements = ref({})
    const { getElement } = contentGeneralService()
    const { getList } = contentHelpService()
    Promise.all([
      getElement(1),
      getList(),
    ]).then(([about, help]) => {
      aboutContent.value = about
      helpElements.value = help.items
    })
    return { aboutContent, helpElements, generateIndexVariant }
  },
}
</script>
